<template>
    <v-row>
      <v-col cols="12" class="mt-0 pt-0 pb-0" t-data="info-input">
        <v-tabs
          v-model="selectedMeta"
          class="status-tab body-regular"
          color="primary500Color"
          slider-color="primary500Color"
          align-with-title
        >
          <v-tab
            t-data="tab-panel"
            v-for="(meta, index) in metaList"
            :key="index"
            :value="index"
          >
            {{ meta.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedMeta">
          <v-tab-item
            v-for="(meta, index) in metaList"
            :key="index"
            :value="index"
          >
            <v-card class="border-card mt-6 mb-4">
              <v-card-text>
                <v-row align="start" justify="start" class="mt-0 px-2">
                  <v-col cols="6">
                    <h6 class="mb-6 card-title" t-data="thai-info-header">
                      Thai Content
                    </h6>
                  </v-col>
                  <v-col cols="3">
                    <h6 class="mb-6 card-title" t-data="eng-info-header">
                      English Content
                    </h6>
                  </v-col>
                  <v-col t-data="checkbox-duplicate">
                    <v-checkbox
                      v-model="duplicateContent[meta.value]"
                      :disabled="disabledEdit"
                      hide-details
                      label="Duplicate Thai content"
                      color="secondary"
                      class="check-box-text body-regular mt-0 pt-0"
                      @click="duplicateThaiContent(meta.value)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row align="start" justify="start" class="mt-0">
                  <v-col cols="6" t-data="thai-info-content">
                    <content-by-lang
                      class="px-0 ml-3"
                      lang="th"
                      image-field-name="Meta image"
                      :title="doctorObject.meta[meta.value][0].title"
                      :url="doctorObject.meta[meta.value][0].image"
                      :description.sync="
                        doctorObject.meta[meta.value][0].description
                      "
                      :disabledEdit="disabledEdit"
                      :setImage="v => setMetaImage({type: meta.value, ...v})"
                      :setImageError="
                        v => setMetaImageError({type: meta.value, ...v})
                      "
                      :isImageError="isMetaImageError[meta.value].th"
                      :imageErrorMessage="metaImageErrorMessage[meta.value].th"
                      :show-description="true"
                      :required-title="false"
                      :required-image="false"
                      :is-textarea-description="true"
                      cols-image="12"
                      cols-title="12"
                      order-image="2"
                      order-title="1"
                      @changeUrl="v => changeUrlTh(v, meta.value)"
                      @changeTitle="v => changeTitleTh(v, meta.value)"
                      @changeDescription="v => changeDescriptionTh(v, meta.value)"
                      @removeImage="removeImageTh(meta.value)"
                    ></content-by-lang>
                  </v-col>
                  <v-col cols="6" t-data="eng-info-content">
                    <content-by-lang
                      class="px-0"
                      lang="en"
                      image-field-name="Meta image"
                      :title="doctorObject.meta[meta.value][1].title"
                      :url="doctorObject.meta[meta.value][1].image"
                      :description.sync="
                        doctorObject.meta[meta.value][1].description
                      "
                      :disabledEdit="disabledEdit"
                      :setImage="v => setMetaImage({type: meta.value, ...v})"
                      :setImageError="
                        v => setMetaImageError({type: meta.value, ...v})
                      "
                      :isImageError="isMetaImageError[meta.value].en"
                      :imageErrorMessage="metaImageErrorMessage[meta.value].en"
                      :show-description="true"
                      :required-title="false"
                      :required-image="false"
                      :is-textarea-description="true"
                      cols-image="12"
                      cols-title="12"
                      order-image="2"
                      order-title="1"
                      @changeTitle="v => changeTitleEn(v, meta.value)"
                      @removeImage="removeImageEn(meta.value)"
                    ></content-by-lang>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </template>
  <script>
  import ContentByLang from '@/components/ContentByLang'
  import {metaText} from '@/constants/package'
  import {mapActions, mapState} from 'vuex'
  
  export default {
    name: 'MetaContent',
    components: {
      ContentByLang
    },
    props: {
      disabledEdit: {type: Boolean, required: true}
    },
    data() {
      return {
        selectedMeta: 0
      }
    },
    computed: {
      metaList() {
        const types = []
        for (let key in metaText) {
          if (metaText.hasOwnProperty(key)) {
            types.push({name: metaText[key], value: key})
          }
        }
        return types
      },
      ...mapState('doctor', [
        'duplicateContent',
        'isMetaImageError',
        'metaImageErrorMessage',
        'doctorObject'
      ])
    },
    methods: {
      changeUrlTh(val, type) {
        if (this.duplicateContent[type]) {
          this.setMetaImage({lang: 'en', type: type, file: val})
          this.setMetaImageError({
            lang: 'en',
            type: type,
            isError: false,
            message: ''
          })
        }
      },
      changeTitleTh(val, type) {
        this.doctorObject.meta[type][0].title = val
        if (this.duplicateContent[type]) {
          this.doctorObject.meta[type][1].title = val
        }
      },
      changeDescriptionTh(val, type) {
        this.doctorObject.meta[type][0].description = val
        if (this.duplicateContent[type]) {
          this.doctorObject.meta[type][1].description = val
        }
      },
      removeImageTh(type) {
        this.doctorObject.meta[type][0].image = ''
        if (this.duplicateContent[type]) {
          this.doctorObject.meta[type][1].image = ''
        }
      },
      changeTitleEn(val, type) {
        this.doctorObject.meta[type][1].title = val
      },
      removeImageEn(type) {
        this.doctorObject.meta[type][1].image = ''
      },
      duplicateThaiContent(type) {
        if (this.duplicateContent[type]) {
          this.doctorObject.meta[type][1].title =
            this.doctorObject.meta[type][0].title
          this.doctorObject.meta[type][1].description =
            this.doctorObject.meta[type][0].description
          this.doctorObject.meta[type][1].image =
            this.doctorObject.meta[type][0].image
        } else {
          this.doctorObject.meta[type][1].title = null
          this.doctorObject.meta[type][1].description = ''
          this.doctorObject.meta[type][1].image = ''
        }
      },
      ...mapActions('doctor', ['setMetaImage', 'setMetaImageError'])
    }
  }
  </script>
  